import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { WhiteButton } from '../shared/buttons'
import { H2, P } from '../shared/text'
import { media } from '../utils/style-utils'

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  color: ${props => props.theme.colors.azure};
  

  ${media.small`
    padding: 54px 20px 84px 20px;
    max-width: 100vw;
    overflow: hidden;
  `}
  ${media.medium`
    padding: 70px 60px 104px 60px;
    max-width: none;
  `}
  ${media.large`
    padding: 90px 100px;
  `}

  & > p {
    visibility: hidden;

    max-width: 954px;
    text-align: center;
  }
`

const StyledH2 = styled(H2)`
  visibility: hidden;

  ${media.small`
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  `}
  ${media.medium`
    font-size: 80px;
    line-height: 90px;
    margin-bottom: 30px;
  `}
`

const GreenH2 = styled(StyledH2)`
  visibility: visible;

  color: ${props => props.theme.colors.healerite};
`

const SlideGridContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;

  display: grid;
  align-items: center;

  ${media.small`
    max-width: 350px;
  `}
  ${media.medium`
    grid-template-columns: 1fr .5fr;
    grid-column-gap: 60px;
    max-width: 1268px;
  `}
  ${media.large`
    grid-template-columns: 1fr auto;
    grid-column-gap: 100px;
    padding: 0 60px;
  `}
`

const DesktopImageWrapper = styled.div`
  visibility: hidden;

  ${media.small`
    display: none;
  `}
  ${media.medium`
    display:block;
    height: 300px;
    width: 200px;
  `}
  ${media.large`
    height: 386px;
    width: 300px;
  `}
`

const MobileImageWrapper = styled.div`
  visibility: hidden;

  height: 239px;
  width: 100%;

  ${media.medium`
    display: none;
  `}
`

const ButtonRow = styled.div`
  width: 100%;

  display: grid;
  grid-gap: 12px;

  ${media.medium`
    height: 37px;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 21px;
  `}
`

const LabelButton = styled(WhiteButton)`
  visibility: hidden;

  width: 100%;
  background-color: ${props =>
    props.active ? props.theme.colors.healerite : props.theme.colors.white};
  border-color: ${props =>
    props.active ? props.theme.colors.healerite : props.theme.colors.azure};
`

const LabelRowGrid = styled.div`
  width: 100%;
  display: grid;
  grid-column-gap: 30px;

  ${media.small`
    grid-template-columns: 1fr 1.5fr;
    margin: 30px 0 38px 0;
  `}
  ${media.medium`
    grid-template-columns: 1fr;
    margin: 50px 0 58px 0;
  `}
  ${media.large`
    max-width: 1268px;
    margin: 75px auto 105px auto;
  `}
`

const query = graphql`
  query {
    lightbulb: file(
      relativePath: { regex: "/about/how-we-build/lightbulb.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plant: file(relativePath: { regex: "/about/how-we-build/plant.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beaker: file(relativePath: { regex: "/about/how-we-build/beaker.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rocket: file(relativePath: { regex: "/about/how-we-build/rocket.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    stones: file(relativePath: { regex: "/about/how-we-build/stones.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const HowWeBuild = () => {
  const { lightbulb, plant, beaker, rocket, stones } = useStaticQuery(query)
  const [activeIdx, setActiveIdx] = useState(0)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline({
      defaults: { opacity: 0, duration: 0.5, y: 10 },
      scrollTrigger: '.how-we-build__title',
    })
    tl.to(
      '.how-we-build__title, .how-we-build__subtitle, .how-we-build__label-button, .how-we-build__slide-grid-container, .how-we-build__image',
      {
        visibility: 'visible',
      }
    )
    tl.from('.how-we-build__title', {})
    tl.from('.how-we-build__subtitle', {}, '<0.15')
    tl.from('.how-we-build__label-button', { y: 0, stagger: 0.15 }, '<0.15')
    tl.from(
      '.how-we-build__slide-grid-container',
      { y: 0, duration: 1 },
      '<0.5'
    )
    tl.from('.how-we-build__image', { y: 0, x: 40 }, '<')
  }, [])

  const slides = [
    {
      label: 'Ideate',
      description:
        'At the onset of any brand build, we start by assessing the market landscape and aligning opportunities with our unique strengths. From there, we conduct thorough competitive analyses, brainstorm product ideas, and evaluate ideas based on our internal business scorecard. This allows us to prioritize and select the ideas worth pursuing.',
      img: {
        fluid: lightbulb.childImageSharp.fluid,
        alt: 'lightbulb',
      },
    },
    {
      label: 'Develop',
      description:
        'Once we’ve selected an idea to move forward with, we begin development. This is where we work on identifying our key objectives, shaping the brand identity, and mapping out our product journey. As we plan the build of a new brand, we leverage our internal resources in order to expedite our timeframe and optimize the development process.',
      img: {
        fluid: plant.childImageSharp.fluid,
        alt: 'Plant',
      },
    },
    {
      label: 'Test',
      description:
        'Now, it’s time get testing. Here, we gauge consumer interest and look for feedback by surveying our existing captive audiences. As we collect that feedback, we use it to iterate on our brand idea and make sure that everything is in good shape before we go to market.',
      img: {
        fluid: beaker.childImageSharp.fluid,
        alt: 'Beaker with green liquid',
      },
    },
    {
      label: 'Launch',
      description:
        'Once we’ve tested and fine-tuned our idea, we’re ready to launch. This is the part of the process where we manufacture our product, develop a content strategy, and execute marketing and partnership opportunities to expedite growth off the ground.',
      img: {
        fluid: rocket.childImageSharp.fluid,
        alt: 'Origami rocket ship',
      },
    },
    {
      label: 'Scale',
      description:
        'We may have launched, but it’s still just the beginning. In order to scale our new brand, we need our team of talented builders to keep the momentum going. As we grow, we also engage with subject matter experts in the space and occasionally work with our existing network to source external funding.',
      img: {
        fluid: stones.childImageSharp.fluid,
        alt: 'Stacked stones',
      },
    },
  ]

  const slideCount = slides.length
  useEffect(() => {
    // Cycles through all of the slides so their images render before the user scrolls there
    let ceil = slideCount - 1
    while (ceil > 0) {
      ceil--
      setActiveIdx(ceil)
    }
  }, [slideCount])

  const labelsArray = slides.map(slide => slide.label)

  const handleLabelClick = idx => {
    const tl = gsap.timeline({ defaults: { ease: 'power4.out' } })

    tl.to('.how-we-build__slide-grid-container', {
      opacity: 0,
      duration: 0.5,
      y: 40,
    })
    tl.call(() => setActiveIdx(idx), null, '+=0')
    tl.to('.how-we-build__slide-grid-container', {
      opacity: 1,
      duration: 0.5,
      y: -40,
    })
    tl.fromTo(
      '.how-we-build__image',
      { opacity: 0, x: 40 },
      { opacity: 1, x: 0 },
      '<'
    )
  }

  return (
    <Container>
      <StyledH2 className="how-we-build__title">How we build</StyledH2>
      <P className="how-we-build__subtitle">
        Our unique model gives us a proven formula for optimizing the path to
        success while spurring a flywheel effect that allows for repeatability.
      </P>

      <LabelRowGrid>
        <ButtonRow>
          {labelsArray.map((label, idx) => (
            <LabelButton
              active={idx === activeIdx}
              onClick={() => handleLabelClick(idx)}
              className="how-we-build__label-button"
              key={label}
            >
              {label}
            </LabelButton>
          ))}
        </ButtonRow>
        <MobileImageWrapper className="how-we-build__image">
          <Img
            fluid={slides[activeIdx].img.fluid}
            alt={slides[activeIdx].img.alt}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </MobileImageWrapper>
      </LabelRowGrid>

      <SlideGridContainer className="how-we-build__slide-grid-container">
        <div>
          <GreenH2>{`0${activeIdx + 1}.`}</GreenH2>
          <P>{slides[activeIdx].description}</P>
        </div>
        <DesktopImageWrapper className="how-we-build__image">
          <Img
            fluid={slides[activeIdx].img.fluid}
            alt={slides[activeIdx].img.alt}
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </DesktopImageWrapper>
      </SlideGridContainer>
    </Container>
  )
}

export default HowWeBuild
