import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { H1, H2, P } from '../shared/text'
import { media } from '../utils/style-utils.js'

import icon from '../../img/about/bright-ideas-icon.svg'

const Container = styled.div`
  display: grid;
  place-items: center;
  background-color: ${props => props.theme.colors.azure};
  

  ${media.small`
    padding: 80px 0px;
  `}
  ${media.medium`
    padding: 120px 40px;
  `}
  ${media.large`
    padding: 120px 40px;
  `}
`

const Content = styled.div`
  width: 100%;
  max-width: 1268px;
  margin: 0 auto;

  display: grid;

  ${media.large`
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 100px;
  `}
`

const RightColumn = styled.div`
  margin-top: 20px;
  margin-left: 0;
  margin-right: 60px; // Offsets GSAP

  display: grid;
  grid-row-gap: 60px;
`

const TextGroup = styled.div`
  visibility: hidden;

  display: grid;
  grid-row-gap: 20px;
  grid-auto-rows: max-content;
`

const LeftTextGroup = styled(TextGroup)`
  grid-template-rows: max-content max-content 1fr;
  align-items: center;

  margin-left: 60px; // Offsets GSAP
  margin-right: 0;
`

const WhiteP = styled(P)`
  color: ${props => props.theme.colors.white};
`

const StyledH1 = styled(H1)`
  color: ${props => props.theme.colors.healerite};

  &::after {
    display: none;
  }

  ${media.small`
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 10px;
  `}
  ${media.large`
    font-size: 80px;
    line-height: 90px;
    margin-bottom: 30px;
  `}
`

const GreenH2 = styled(H2)`
  font-family: Apercu-Medium, sans-serif;
  color: ${props => props.theme.colors.healerite};

  ${media.large`
    line-height: 52px;

    &:before {
      content: '';
      height: 18px;
      width: 18px;
      position: absolute;
      margin: 15px 0 0 -34px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.healerite};
    }
  `}
`

const rotate = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(-360deg) }
`

const IconImg = styled.img`
  animation: ${rotate} 15s linear infinite;

  ${media.small`
    width: 130px;
    height: 130px;
    margin: 36px 0 43px 0;
  `}
  ${media.large`
    width: 224px;
    height: 224px;
    margin: 0;
  `}
`

const BetterApproach = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const tl = gsap.timeline({
      delay: 0,
      scrollTrigger: '.better-approach__one',
    })

    tl.to('.better-approach__one', {
      autoAlpha: 1,
      x: -30,
      y: -30,
      duration: 1,
    })
    tl.to(
      '.better-approach__two',
      {
        autoAlpha: 1,
        stagger: 0.25,
        x: 30,
        y: -30,
        duration: 1,
      },
      '<'
    )
  }, [])
  return (
    <Container>
      <Content>
        <LeftTextGroup className="better-approach__one">
          <StyledH1>A better approach</StyledH1>
          <WhiteP>
            Growth strategies of old are losing steam, and companies’
            early-stage priorities are shifting. So, we’re changing things up,
            too.
          </WhiteP>
          <IconImg src={icon} alt="Bringing bright ideas to light" />
        </LeftTextGroup>
        <RightColumn>
          <TextGroup className="better-approach__two">
            <GreenH2>Tech-enabled platform</GreenH2>
            <WhiteP>
              We’ve revolutionized the company-building process by creating a
              platform to optimize capital efficiency and growth velocity as we
              launch new brands.
            </WhiteP>
          </TextGroup>
          <TextGroup className="better-approach__two">
            <GreenH2>Proprietary insights and expertise</GreenH2>
            <WhiteP>
              We combine our experience with data-driven insights, owned
              channels, and our evolving knowledge base to increase the
              probability of success.
            </WhiteP>
          </TextGroup>
          <TextGroup className="better-approach__two">
            <GreenH2>Structure for repeatable success</GreenH2>
            <WhiteP>
              By codifying our path to success as we go, we ensure that each new
              idea or launch is informed and optimized by the learnings of the
              last.
            </WhiteP>
          </TextGroup>
        </RightColumn>
      </Content>
    </Container>
  )
}

export default BetterApproach
