import React from 'react'

import SEO from '../components/shared/SEO'
import Layout from '../components/Layout'
import About from '../components/About'

const data = [
  {
    name: 'Ideate',
    // icon: LightbulbIcon,
    description:
      'At the onset of any brand build, we start by assessing the market landscape and aligning opportunities with our unique strengths. From there, we conduct thorough competitive analyses, brainstorm product ideas, and evaluate ideas based on our internal business scorecard. This allows us to prioritize and select the ideas worth pursuing.',
  },
  {
    name: 'Develop',
    // icon: FingerprintIcon,
    description:
      'Once we’ve selected an idea to move forward with, we begin development. This is where we work on identifying our key objectives, shaping the brand identity, and mapping out our product journey. As we plan the build of a new brand, we leverage our internal resources in order to expedite our timeframe and optimize the development process.',
  },
  {
    name: 'Test',
    // icon: BeakerIcon,
    description:
      'Now, it’s time get testing. Here, we gauge consumer interest and look for feedback by surveying our existing captive audiences. As we collect that feedback, we use it to iterate on our brand idea and make sure that everything is in good shape before we go to market.',
  },
  {
    name: 'Launch',
    // icon: RocketshipIcon,
    description:
      'Once we’ve tested and fine-tuned our idea, we’re ready to launch. This is the part of the process where we manufacture our product, develop a content strategy, and execute marketing and partnership opportunities to expedite growth off the ground.',
  },
  {
    name: 'Scale',
    // icon: ChartIcon,
    description:
      'We may have launched, but it’s still just the beginning. In order to scale our new brand, we need our team of talented builders to keep the momentum going. As we grow, we also engage with subject matter experts in the space and occasionally work with our existing network to source external funding.',
  },
]

const AboutPage = ({ location }) => {
  return (
    <>
      <SEO
        title="About Us"
        description="Every idea starts somewhere. The best ones start here..."
      />
      <Layout location={location}>
        <About data={data} />
      </Layout>
    </>
  )
}

export default AboutPage
