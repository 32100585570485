import React from 'react'
import styled from 'styled-components'

import AboveTheFold from './AboveTheFold'
import BetterApproach from './BetterApproach'
import HowWeBuild from './HowWeBuild'

const Main = styled.div`
  width: 100%;
`

const About = () => {
  return (
    <Main>
      <AboveTheFold />
      <BetterApproach />
      <HowWeBuild />
    </Main>
  )
}

export default About
